import FireBridge from '@/sys/FireBridge/FireBrige'
import FireBridgeInfo from '@/views/FireBridgeInfo/FireBridgeInfo'
import Menu from '../Menu'
import backgroundImage from '@/assets/icons/firebeeIconFade.svg'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
    components: { FireBridgeInfo, Menu, LoadingSpinner },
    data() {
        return {
            isToLog: false,
            isLoading: true,
            CHUNK_SIZE: 40000,
            desktopName: null,
            lastStatus: null,
            showStatus: false,
            showFault: false,
            statusMsg: "",
            files: [],
            selectedFiles: [],
            selectedFileCount: 0,
            statusMessage: '',
            statusClass: '',
            onMessageStatus: false,
            fireBridge: new FireBridge(this.device._id, "FTP"),
            socket: null,
            currentDir: [],
            fileContent: '',
            isModalVisible: false,
            modalContent: '',
            showCancelConfirmation: false,
            standBy: true,
            directoryFileTypes: {
                "Plantas": [".jpg", ".jpeg", ".png"],
                "Relatorios": [".pdf", ".csv"],
                "Outros": ["*"],
                "Backups": [".sql", ".fhtbkp", ".sfbkp"]
            },
            downloadProgress: {},
            fileBuffer: {},
            messageQueue: [],
            isSending: false,
            DELAY_MS: 50,
            activeUploads: new Map(),
            uploadRetryTimeout: 30000,
            backgroundImage,
            searchQuery: '',
            sortBy: 'name',
            sortDirection: 'asc',
            filteredFiles: [],
            retryDelayBase: 1000,
            maxRetryAttempts: 100,
            pendingTransfers: new Map(), // Para armazenar transferências pendentes
            reconnectionAttempts: 0,
            maxReconnectionAttempts: 5,
            reconnectionDelay: 10000,
            isReconnecting: false
        };
    },

    props: {
        device: {
            type: Object,
            default: null
        }
    },

    computed: {
        displayedFiles() {
            if (!this.searchQuery) {
                return this.sortFiles(this.files);
            }
            const query = this.searchQuery.toLowerCase();
            const filtered = this.files.filter(file =>
                file.name.toLowerCase().includes(query)
            );
            return this.sortFiles(filtered);
        },
    },

    mounted() {
        if (this.device != null) {
            this.connect();
        } else {
            console.log("Faltou as informações do dispositivo");
            router.push({ path: '/ListOfDevices' });
        }
    },

    created() {
        this.startOperationMonitor();
    },

    beforeDestroy() {
        this.stopOperationMonitor();
        this.disconnect();
    },

    methods: {
        // Novo método para verificar e retomar transferências automaticamente
        async checkAndResumePendingTransfers() {
            // Verifica transferências ativas no momento da desconexão
            const activeTransfers = Array.from(this.activeUploads.entries());

            for (const [fileName, upload] of activeTransfers) {
                if (upload && !upload.isComplete) {
                    this.setStatusMessage(`Retomando upload de ${fileName}...`, 'info');
                    // Atualiza a flag para indicar que é uma retomada
                    upload.isResume = true;
                    // Retoma do último chunk conhecido
                    await this.processNextChunk(fileName, this.currentDir.join('/'));
                }
            }
        },
        async handleReconnection() {
            if (this.isReconnecting) return;

            this.isReconnecting = true;
            this.reconnectionAttempts++;

            if (this.reconnectionAttempts <= this.maxReconnectionAttempts) {
                this.setStatusMessage(`Tentativa de reconexão ${this.reconnectionAttempts}/${this.maxReconnectionAttempts}...`, 'warning');

                setTimeout(async () => {
                    try {
                        await this.connect();
                        this.isReconnecting = false;
                        this.reconnectionAttempts = 0;

                        // Após reconexão bem-sucedida, verifica e retoma transferências
                        if (!this.standBy) {
                            this.setStatusMessage('Reconectado. Retomando transferências...', 'info');
                            await this.checkAndResumePendingTransfers();
                        }
                    } catch (error) {
                        this.isReconnecting = false;
                        this.handleReconnection();
                    }
                }, this.reconnectionDelay);
            } else {
                this.setStatusMessage('Não foi possível reconectar. Tente novamente mais tarde.', 'error');
                this.isReconnecting = false;
                this.reconnectionAttempts = 0;
            }
        },
        reconnect() {
            console.log("Iniciando conexão...")
            this.btnMenu = document.getElementById('btnMenu');
        },
        goBack() {
            if (this.standBy) {
                this.standBy = false;
                this.currentDir.pop();
                this.getFiles();
                this.standBy = true;
            } else {
                this.setStatusMessage('Aguarde, processando requisição anterior. ', 'info');
            }

        },
        formatFileSize(bytes) {
            if (!bytes) return 'VAZIO';
            const units = ['B', 'KB', 'MB', 'GB', 'TB'];
            let size = bytes;
            let unitIndex = 0;

            while (size >= 1024 && unitIndex < units.length - 1) {
                size /= 1024;
                unitIndex++;
            }

            return `${size.toFixed(1)} ${units[unitIndex]}`;
        },
        // Adicione estas constantes de tipos de arquivo no início do arquivo
        isImageFile(fileName) {
            return /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(fileName);
        },

        // Método que verifica se o arquivo é PDF
        isPdfFile(fileName) {
            return /\.pdf$/i.test(fileName);
        },
        viewFile(fileName) {
            this.DOWNLOAD_MODE = 2;
            this.downloadFile(fileName);
        },
        isTextFile(fileName) {
            return /\.(txt|md|csv|json|xml)$/i.test(fileName);
        },
        // Método para gerenciar transferências pendentes
        async checkPendingTransfers() {
            this.sendMessage({
                action: 'pendingTransfers'
            });
        },

        // Método para retomar transferências
        async resumeTransfer(transfer) {
            if (transfer.type === 'upload') {
                await this.resumeUpload(transfer);
            } else {
                await this.resumeDownload(transfer);
            }
        },

        // Método para retomar upload
        async resumeUpload(transfer) {
            const file = this.pendingTransfers.get(transfer.id)?.file;
            if (!file) return;

            this.standBy = false;
            this.activeUploads.set(transfer.fileName, {
                file,
                currentChunk: transfer.lastProcessedChunk || 0,
                totalChunks: transfer.totalChunks,
                retryCount: 0,
                consecutiveErrors: 0,
                maxRetries: this.maxRetryAttempts,
                timeout: null,
                isResume: true
            });

            await this.processNextChunk(transfer.fileName, transfer.folderPath);
        },

        // Método para retomar download
        async resumeDownload(transfer) {
            this.standBy = false;
            this.fileBuffer[transfer.fileName] = new Array(transfer.totalChunks);
            this.downloadProgress[transfer.fileName] = (transfer.currentChunk / transfer.totalChunks) * 100;

            this.sendMessage({
                action: 'resumeDownload',
                transferId: transfer.id,
                fileName: transfer.fileName,
                currentChunk: transfer.currentChunk
            });
        },

        disconnect() {
            if (this.socket) {
                this.socket.close();
            }
        },

        sortFiles(files) {
            return [...files].sort((a, b) => {
                let comparison = 0;
                switch (this.sortBy) {
                    case 'name':
                        comparison = a.name.localeCompare(b.name);
                        break;
                    case 'size':
                        comparison = (a.size || 0) - (b.size || 0);
                        break;
                    case 'type':
                        comparison = a.isDirectory === b.isDirectory ?
                            0 : a.isDirectory ? -1 : 1;
                        break;
                }
                return this.sortDirection === 'asc' ? comparison : -comparison;
            });
        },

        calculateRetryDelay(retryCount) {
            const baseDelay = this.retryDelayBase;
            const exponentialDelay = Math.min(baseDelay * Math.pow(2, retryCount), 30000);
            const jitter = Math.random() * 1000;
            return exponentialDelay + jitter;
        },

        async retryUpload(fileName, path) {
            const upload = this.activeUploads.get(fileName);
            if (!upload) return;

            upload.retryCount++;
            upload.consecutiveErrors = (upload.consecutiveErrors || 0) + 1;

            if (upload.consecutiveErrors >= 3) {
                this.setStatusMessage(`Problemas na conexão detectados. Tentando reiniciar upload de ${fileName}...`, 'warning');
                await this.handleReconnection();
                upload.consecutiveErrors = 0;
            }

            const retryDelay = this.calculateRetryDelay(upload.retryCount);
            this.setStatusMessage(`Tentativa ${upload.retryCount} de reenvio para ${fileName}. Próxima tentativa em ${Math.round(retryDelay / 1000)} segundos...`, 'warning');

            upload.timeout = setTimeout(() => {
                this.processNextChunk(fileName, path);
            }, retryDelay);
        },
        async uploadFiles() {
            if (!this.standBy) {
                this.setStatusMessage('Aguarde, processando requisição anterior.', 'info');
                return;
            }

            if (!this.selectedFiles.length) return;

            const allowedDirs = ['Backups', 'Plantas', 'Outros'];
            const currentDirName = this.currentDir[this.currentDir.length - 1];

            if (!allowedDirs.includes(currentDirName)) {
                this.setStatusMessage('O upload só é permitido nos diretórios "Backups", "Plantas" ou "Outros".', 'error');
                return;
            }

            this.standBy = false;
            this.selectedFileCount = 0;

            const file = this.selectedFiles[0];
            this.selectedFiles.splice(0, 1);
            await this.uploadFileInChunks(file);
        },
        async processNextChunk(fileName, path) {
            const upload = this.activeUploads.get(fileName);
            if (!upload) return;

            try {
                const start = upload.currentChunk * this.CHUNK_SIZE;
                const end = Math.min(start + this.CHUNK_SIZE, upload.file.size);
                const chunk = upload.file.slice(start, end);

                const reader = new FileReader();
                reader.onload = () => {
                    this.sendMessage({
                        action: 'uploadFiles',
                        path,
                        fileName,
                        chunk: reader.result,
                        chunkIndex: upload.currentChunk,
                        totalChunks: upload.totalChunks,
                        isResume: upload.isResume
                    });

                    upload.timeout = setTimeout(() => {
                        if (!this.standBy) {
                            this.retryUpload(fileName, path);
                        }
                    }, this.uploadRetryTimeout);
                };

                reader.onerror = () => {
                    console.error('Erro ao ler chunk:', reader.error);
                    if (!this.standBy) {
                        this.retryUpload(fileName, path);
                    }
                };

                reader.readAsDataURL(chunk);
            } catch (error) {
                console.error('Erro ao processar chunk:', error);
                if (!this.standBy) {
                    this.retryUpload(fileName, path);
                }
            }
        },

        handleSocketResponse(data) {
            if (data.files) {
                this.files = data.files;
                this.isLoading = false;
            }

            if (data.pendingTransfers) {
                data.transfers.forEach(transfer => {
                    this.pendingTransfers.set(transfer.id, transfer);
                });

                if (data.transfers.length > 0) {
                    this.showPendingTransfersDialog();
                }
            }

            switch (data.action) {
                case 'uploadChunkAck': {
                    if (!this.standBy) {
                        const upload = this.activeUploads.get(data.fileName);
                        if (!upload) return;

                        if (upload.timeout) {
                            clearTimeout(upload.timeout);
                            upload.timeout = null;
                        }

                        const progress = Math.round(data.progress);
                        this.setStatusMessage(`Enviando arquivo: ${data.fileName} - Progresso: ${progress}%`,
                            progress === 100 ? 'success' : 'info');

                        upload.currentChunk = data.nextExpectedChunk;
                        upload.retryCount = 0;
                        upload.consecutiveErrors = 0;
                        upload.lastProgress = progress; // Salva o progresso atual

                        if (progress === 100) {
                            upload.isComplete = true;
                        }

                        if (upload.currentChunk < upload.totalChunks) {
                            this.processNextChunk(data.fileName, this.currentDir.join('/'));
                        }
                    }
                    break;
                }

                case 'uploadChunkRetry': {
                    if (!this.standBy) {
                        const upload = this.activeUploads.get(data.fileName);
                        if (!upload) return;
                        upload.currentChunk = data.expectedChunk;
                        this.processNextChunk(data.fileName, this.currentDir.join('/'));
                    }
                    break;
                }

                case 'downloadInit': {
                    if (!this.standBy) {
                        const fileName = data.fileName;
                        this.fileBuffer[fileName] = new Array(data.totalChunks);
                        this.downloadProgress[fileName] = 0;
                        this.sendMessage({
                            action: 'readyForChunk',
                            fileName
                        });
                    }
                    break;
                }

                case 'chunk': {
                    if (!this.standBy) {
                        const fileName = data.fileName;
                        this.fileBuffer[fileName][data.chunkIndex] = data.chunk;
                        const progress = Math.round(((data.chunkIndex + 1) / data.totalChunks) * 100);
                        this.downloadProgress[fileName] = progress;

                        this.setStatusMessage(`Baixando arquivo: ${fileName} - Progresso: ${progress}%`, "info");

                        this.sendMessage({
                            action: 'chunkAck',
                            fileName,
                            chunkAck: data.chunkIndex
                        });

                        if (data.final) {
                            if (this.DOWNLOAD_MODE == 1) {
                                this.finalizeDownload(fileName);
                            } else {
                                const blob = this._combineChunksToBlob2(this.fileBuffer[fileName]);
                                this.openModal(fileName, blob);
                                this._cleanupAfterDownload(fileName);
                            }
                            this.standBy = true;
                        } else {
                            this.sendMessage({
                                action: 'readyForChunk',
                                fileName
                            });
                        }
                    }
                    break;
                }
            }

            if (data.uploadSuccess) {
                this.getFiles();
                if (this.selectedFiles.length > 0) {
                    const file = this.selectedFiles[0];
                    this.selectedFiles.splice(0, 1);
                    this.uploadFileInChunks(file);
                } else {
                    this.standBy = true;
                }
            }

            if (data.deleteSuccess) {
                this.getFiles();
                this.setStatusMessage('Arquivo excluído com sucesso!', 'success');
                this.standBy = true;
            }

            if (data.error) {
                console.log("dataError: " + data.error);
                this.setStatusMessage(data.error, 'error');
                this.standBy = true;
            }
        },

        connect() {
            this.btnMenu = document.getElementById('btnMenu');
            this.socket = this.fireBridge;

            this.socket.onopen = async (ev) => {
                console.log("Conectado ao WebSocket");
                await this.getFiles();

                // Se estava no meio de uma operação quando desconectou
                if (!this.standBy) {
                    await this.checkAndResumePendingTransfers();
                }

                // Verifica outras transferências pendentes do servidor
                await this.checkPendingTransfers();
            };
            this.socket.onmessage = (event) => {
                let buffer = '';
                this.isLoading = false;
                try {
                    const text = event.data instanceof ArrayBuffer
                        ? new TextDecoder().decode(event.data)
                        : event.data;

                    buffer += text;

                    let startIndex = 0;
                    let braceCount = 0;
                    let messages = [];

                    for (let i = 0; i < buffer.length; i++) {
                        if (buffer[i] === '{') {
                            if (braceCount === 0) {
                                startIndex = i;
                            }
                            braceCount++;
                        } else if (buffer[i] === '}') {
                            braceCount--;

                            if (braceCount === 0) {
                                try {
                                    const jsonStr = buffer.slice(startIndex, i + 1).replace(/\\"/g, '"');
                                    const parsed = JSON.parse(jsonStr);
                                    messages.push(parsed);

                                    if (i + 1 < buffer.length) {
                                        buffer = buffer.slice(i + 1);
                                        i = -1;
                                    } else {
                                        buffer = '';
                                    }
                                } catch (e) {
                                    console.warn('Mensagem JSON inválida encontrada, ignorando...', e);
                                }
                            }
                        }
                    }

                    messages.forEach(msg => {
                        try {
                            this.handleSocketResponse(msg);
                        } catch (e) {
                            this.setStatusMessage('Erro ao processar requisição.', 'error');
                            console.error('Erro ao processar mensagem:', e, msg);
                        }
                    });

                } catch (error) {
                    this.setStatusMessage('Erro no processamento do WebSocket.', 'error');
                    console.error('Erro geral no processamento do WebSocket:', error);
                    buffer = '';
                }
            };

            this.socket.onclose = (ev) => {
                this.setStatusMessage('Servidor desconectado. Tentando reconectar...', 'warning');
                console.log('Socket desconectado. Iniciando processo de reconexão...');
                this.standBy = true;
                this.handleReconnection();
            };

            this.socket.onerror = (err) => {
                console.error('Erro Socket:', err);
                this.setStatusMessage('Erro: Verifique a conexão da Central FHT', 'error');
                this.files = '';
                this.standBy = true;
                this.handleReconnection();
            };

            this.socket.connect();
        },
        exit() {
            this.socket.close()
            this.fireBridge.close()
            this.$router.push({ name: 'AcessType', params: { device: this.device } });
        },
        showPendingTransfersDialog() {
            const pendingCount = this.pendingTransfers.size;
            if (pendingCount > 0) {
                const message = `Existem ${pendingCount} transferências pendentes. Deseja retomá-las?`;
                if (confirm(message)) {
                    this.resumePendingTransfers();
                } else {
                    this.pendingTransfers.clear();
                }
            }
        },
        // Fecha o diálogo de confirmação
        closeCancelConfirmation() {
            this.showCancelConfirmation = false;
        },

        // Confirma o cancelamento
        confirmCancel() {
            this.cancelOperation(); // Chama a função original de cancelamento
            this.closeCancelConfirmation();
        },

        cancelOperation() {
            console.log("Cancelando operações...")
            this.selectedFiles = [];
            this.selectedFileCount = 0;
            this.fileContent = '';
            this.downloadProgress = {};
            this.fileBuffer = {};
            this.messageQueue = [],
                this.sendMessage({
                    action: 'deleteUploads',
                });
            this.standBy = true;
            this.setStatusMessage('Ação cancelada pelo usuário', 'info');
        },

        async resumePendingTransfers() {
            for (const transfer of this.pendingTransfers.values()) {
                await this.resumeTransfer(transfer);
            }
        },

        sendMessageWithDelay(message) {
            try {
                this.messageQueue.push({ message });
                if (!this.isSending) {
                    this.processQueue();
                }
            } catch (error) {
                this.setStatusMessage('Erro ao enviar mensagem', 'error');
                console.error('sendMessageWithDelay error:', error);
            }
        },

        processQueue() {
            if (this.messageQueue.length === 0) {
                this.isSending = false;
                return;
            }

            this.isSending = true;
            const { message } = this.messageQueue.shift();

            try {
                const messageString = JSON.stringify(message);
                this.socket.send(messageString);

                setTimeout(() => {
                    this.processQueue();
                }, this.DELAY_MS);
            } catch (error) {
                console.error('Erro ao processar fila:', error);
                setTimeout(() => {
                    this.processQueue();
                }, this.DELAY_MS);
            }
        },

        sendMessage(data) {
            this.sendMessageWithDelay(data);
        },

        downloadFile(fileName) {
            if (this.standBy) {
                this.standBy = false;
                const path = this.currentDir.join('/');
                this.sendMessage({
                    action: 'downloadFile',
                    path,
                    fileName,
                });
                this.setStatusMessage(`Aguarde, baixando ${fileName}...`, 'info');
            } else {
                this.setStatusMessage('Aguarde, processando requisição anterior.', 'info');
            }
        },

        async uploadFileInChunks(file) {
            const totalChunks = Math.ceil(file.size / this.CHUNK_SIZE);
            const path = this.currentDir.join('/');

            this.activeUploads.set(file.name, {
                file,
                currentChunk: 0,
                totalChunks,
                retryCount: 0,
                consecutiveErrors: 0,
                maxRetries: this.maxRetryAttempts,
                timeout: null,
                isResume: false
            });

            await this.processNextChunk(file.name, path);
        },

        finalizeDownload(fileName) {
            try {
                const blob = this._combineChunksToBlob(this.fileBuffer[fileName]);
                this._triggerDownload(blob, fileName);
                this._cleanupAfterDownload(fileName);
            } catch (error) {
                console.error(`Erro ao finalizar download de ${fileName}:`, error);
                this.setStatusMessage(`Erro ao baixar ${fileName}: ${error.message}`, 'error');
            }
        },
        // // Navegar para dentro de uma pasta
        navigate(file) {
            if (this.standBy) {
                if (file.isDirectory) {
                    this.currentDir.push(file.name);
                    this.getFiles();
                }
            }
        },
        getAllowedExtensions() {
            const currentDirName = this.currentDir[this.currentDir.length - 1];
            const allowedExtensions = this.directoryFileTypes[currentDirName] || [];
            return allowedExtensions.join(",");
        },

        _triggerDownload(blob, fileName) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        },

        _cleanupAfterDownload(fileName) {
            delete this.fileBuffer[fileName];
            delete this.downloadProgress[fileName];
            if (this.DOWNLOAD_MODE === 1) {
                this.setStatusMessage(`Download de ${fileName} concluído!`, 'success');
            }
        },

        _combineChunksToBlob(chunks) {
            const byteArrays = chunks.map(chunk => {
                const binaryString = atob(chunk);
                const byteArray = new Uint8Array(binaryString.length);
                for (let i = 0; i < binaryString.length; i++) {
                    byteArray[i] = binaryString.charCodeAt(i);
                }
                return byteArray;
            });
            return new Blob(byteArrays, { type: 'application/octet-stream' });
        },

        _combineChunksToBlob2(chunks) {
            try {
                const byteArrays = chunks.map(chunk => {
                    try {
                        const binaryString = atob(chunk);
                        const byteArray = new Uint8Array(binaryString.length);
                        for (let i = 0; i < binaryString.length; i++) {
                            byteArray[i] = binaryString.charCodeAt(i);
                        }
                        return byteArray;
                    } catch (error) {
                        console.error('Error decoding chunk:', error);
                        throw error;
                    }
                });
                return new Blob(byteArrays, { type: 'application/octet-stream' });
            } catch (error) {
                console.error('Error combining chunks:', error);
                throw error;
            }
        },

        openModal(fileName, fileData) {
            this.setStatusMessage('');
            this.modalContent = this.renderFileContent(fileName, fileData);
            this.isModalVisible = true;
        },
        renderFileContent(fileName, fileData) {

            if (this.isTextFile(fileName)) {
                const reader = new FileReader();
                reader.readAsText(fileData);

                const containerId = `text-content-${Date.now()}`;
                const searchId = `search-${Date.now()}`;
                const prevBtnId = `prev-${Date.now()}`;
                const nextBtnId = `next-${Date.now()}`;
                const counterId = `counter-${Date.now()}`;
                const caseSensitiveId = `case-sensitive-${Date.now()}`;

                setTimeout(() => {
                    reader.onload = (e) => {
                        const container = document.getElementById(containerId);
                        if (container) {
                            const content = e.target.result;
                            container.textContent = content;

                            let currentIndex = -1;
                            let matches = [];
                            let searchTimeout = null;

                            const updateCounter = () => {
                                const counter = document.getElementById(counterId);
                                if (counter) {
                                    if (matches.length > 0) {
                                        counter.textContent = `${currentIndex + 1} de ${matches.length}`;
                                    } else {
                                        counter.textContent = '0 resultados';
                                    }
                                }
                            };

                            const highlightAll = (searchTerm) => {
                                if (!searchTerm) {
                                    container.innerHTML = content.replace(/\n/g, '<br>');
                                    matches = [];
                                    currentIndex = -1;
                                    updateCounter();
                                    return;
                                }

                                const caseSensitive = document.getElementById(caseSensitiveId)?.checked;

                                // Escapar caracteres especiais do RegExp
                                const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

                                const flags = caseSensitive ? 'g' : 'gi';
                                const regex = new RegExp(escapedSearchTerm, flags);

                                // Encontrar todas as ocorrências
                                let match;
                                matches = [];
                                const contentToSearch = content;

                                while ((match = regex.exec(contentToSearch)) !== null) {
                                    matches.push({
                                        index: match.index,
                                        length: match[0].length
                                    });
                                }

                                // Destacar as ocorrências
                                let htmlContent = content;
                                let offset = 0;

                                matches.forEach((match, idx) => {
                                    const before = htmlContent.slice(0, match.index + offset);
                                    const term = htmlContent.slice(match.index + offset, match.index + match.length + offset);
                                    const after = htmlContent.slice(match.index + match.length + offset);

                                    const isActive = idx === currentIndex;
                                    const highlightClass = isActive ? 'active-highlight' : 'normal-highlight';

                                    const highlightedTerm = `<span class="${highlightClass}">${term}</span>`;
                                    htmlContent = before + highlightedTerm + after;

                                    offset += highlightedTerm.length - term.length;
                                });

                                container.innerHTML = htmlContent.replace(/\n/g, '<br>');
                                updateCounter();
                            };

                            const navigateToMatch = (direction) => {
                                if (matches.length === 0) return;

                                if (direction === 'next') {
                                    currentIndex = currentIndex + 1 >= matches.length ? 0 : currentIndex + 1;
                                } else {
                                    currentIndex = currentIndex - 1 < 0 ? matches.length - 1 : currentIndex - 1;
                                }

                                const searchInput = document.getElementById(searchId);
                                highlightAll(searchInput.value);

                                const activeElement = container.querySelector('.active-highlight');
                                if (activeElement) {
                                    activeElement.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'center'
                                    });
                                }
                            };

                            // Navegação automática após a busca
                            const autoNavigate = () => {
                                if (matches.length > 0) {
                                    // Se já tem uma seleção, vai para o próximo
                                    if (currentIndex >= 0) {
                                        navigateToMatch('next');
                                    } else {
                                        // Se não tem seleção, vai para o primeiro
                                        currentIndex = 0;
                                        highlightAll(document.getElementById(searchId).value);
                                        const activeElement = container.querySelector('.active-highlight');
                                        if (activeElement) {
                                            activeElement.scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'center'
                                            });
                                        }
                                    }
                                }
                            };

                            // Configurar eventos
                            const searchInput = document.getElementById(searchId);
                            const prevBtn = document.getElementById(prevBtnId);
                            const nextBtn = document.getElementById(nextBtnId);
                            const caseSensitiveCheckbox = document.getElementById(caseSensitiveId);

                            if (searchInput) {
                                searchInput.addEventListener('input', (e) => {
                                    // Limpar o timeout anterior se existir
                                    if (searchTimeout) {
                                        clearTimeout(searchTimeout);
                                    }

                                    currentIndex = -1;
                                    highlightAll(e.target.value);

                                    // Configurar novo timeout para navegação automática
                                    if (e.target.value) {
                                        searchTimeout = setTimeout(() => {
                                            autoNavigate();
                                        }, 1000); // 1 segundo de delay
                                    }
                                });

                                searchInput.addEventListener('keydown', (e) => {
                                    if (e.key === 'Enter') {
                                        // Limpar o timeout ao pressionar Enter
                                        if (searchTimeout) {
                                            clearTimeout(searchTimeout);
                                        }

                                        if (e.shiftKey) {
                                            navigateToMatch('prev');
                                        } else {
                                            navigateToMatch('next');
                                        }
                                    }
                                });
                            }

                            if (caseSensitiveCheckbox) {
                                caseSensitiveCheckbox.addEventListener('change', () => {
                                    currentIndex = -1;
                                    highlightAll(searchInput.value);
                                    if (searchInput.value) {
                                        setTimeout(autoNavigate, 100);
                                    }
                                });
                            }

                            if (prevBtn) {
                                prevBtn.addEventListener('click', () => navigateToMatch('prev'));
                            }

                            if (nextBtn) {
                                nextBtn.addEventListener('click', () => navigateToMatch('next'));
                            }
                        }
                    };
                }, 0);

                return `
                    <style>
                        .search-container {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            margin-bottom: 10px;
                        }
                        .search-bar {
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            padding-right: 1cm;
                        }
                        .search-options {
                            display: flex;
                            gap: 16px;
                            align-items: center;
                            font-size: 14px;
                        }
                        .search-input {
                            flex: 1;
                            padding: 8px;
                            border: 1px solid #ddd;
                            border-radius: 4px;
                            font-size: 14px;
                        }
                        .nav-button {
                            padding: 8px 12px;
                            background: #f0f0f0;
                            border: 1px solid #ddd;
                            border-radius: 4px;
                            cursor: pointer;
                            font-size: 14px;
                        }
                        .nav-button:hover {
                            background: #e0e0e0;
                        }
                        .counter {
                            font-size: 14px;
                            color: #666;
                            min-width: 100px;
                            text-align: center;
                        }
                        .active-highlight {
                            background-color: #ffeb3b;
                            font-weight: bold;
                        }
                        .normal-highlight {
                            background-color: #fff59d;
                        }
                        .checkbox-label {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            cursor: pointer;
                        }
                    </style>
                    <div class="search-container">
                        <div class="search-bar">
                            <input type="text" 
                                   id="${searchId}"
                                   class="search-input"
                                   placeholder="Buscar no texto..(Enter para próximo, Shift+Enter para voltar)">
                            <button id="${prevBtnId}" class="nav-button">◀ Anterior</button>
                            <div id="${counterId}" class="counter">0 resultados</div>
                            <button id="${nextBtnId}" class="nav-button">Próximo ▶</button>
                        </div>
                        <div class="search-options">
                            <label class="checkbox-label">
                                <input type="checkbox" id="${caseSensitiveId}">
                                Diferenciar maiúsculas/minúsculas
                            </label>
                        </div>
                    </div>
                    <pre id="${containerId}" 
                         style="white-space: pre-wrap; 
                                word-wrap: break-word; 
                                font-family: monospace; 
                                padding: 10px; 
                                background-color: #f5f5f5; 
                                border: 1px solid #ddd; 
                                border-radius: 4px; 
                                max-height: 500px; 
                                overflow-y: auto;
                                user-select: text;
                                -webkit-user-select: text;
                                -moz-user-select: text;
                                -ms-user-select: text;">
                        Carregando...
                    </pre>
                `;
            }

            if (this.isPdfFile(fileName)) {
                try {
                    // Cria um blob diretamente dos dados
                    const blob = new Blob([fileData], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);

                    // Força a abertura no navegador em vez de download
                    return `
                        <embed 
                            src="${url}#toolbar=0&navpanes=0&scrollbar=0" 
                            type="application/pdf"
                            width="100%" 
                            height="500px" 
                            style="border: none;">`;
                } catch (error) {
                    console.error('Erro ao processar PDF:', error);
                    return '<p>Erro ao processar o arquivo PDF.</p>';
                }
            }

            // Para arquivos de imagem (mantido seu código original que funciona)
            if (this.isImageFile(fileName)) {
                const url = URL.createObjectURL(fileData);
                return `<img src="${url}" style="max-width:100%; max-height:100%; object-fit:contain;">`;
            }

            return '<p>Tipo de arquivo não suportado para visualização.</p>';
        },
        getFiles(searchQuery = '') {
            this.isLoading = true;
            this.setStatusMessage('', 'info');
            const path = this.currentDir.join('/');
            this.searchQuery = searchQuery;

            this.sendMessage({
                action: 'getFiles',
                path,
                searchQuery: this.searchQuery
            });
        },

        setStatusMessage(message, type) {
            this.statusMessage = message;
            this.statusClass = type;
        },
        showCancelConfirmationDialog() {
            this.showCancelConfirmation = true;
        },
        closeModal() {
            this.isModalVisible = false;
            this.modalContent = ''; // Limpa o conteúdo ao fechar
        },
        deleteFile(fileName) {
            if (this.standBy) {
                this.standBy = false;
                if (confirm(`Tem certeza de que deseja excluir o arquivo ${fileName}?`)) {
                    const path = this.currentDir.join('/');
                    this.sendMessage({ action: 'deleteFile', path, fileName });  // Note o '\n' para marcar o fim da mensagem
                    this.setStatusMessage(`Excluindo ${fileName}...`, 'info');
                } else {
                    this.standBy = true;
                }
            } else {
                this.setStatusMessage('Aguarde, processando requisição anterior. ', 'info');
            }
        },
        downloadFileAndSave(fileName) {
            this.DOWNLOAD_MODE = 1;
            this.downloadFile(fileName);

        },
        handleFileUpload(event) {
            const files = Array.from(event.target.files);
            const allowedExtensions = this.getAllowedExtensions().split(",");

            this.selectedFiles = files.filter(file => {
                // Implemente sua lógica de filtro aqui
                return true;
            });

            this.selectedFileCount = this.selectedFiles.length;
        },
        handleSearch() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }

            this.searchTimer = setTimeout(() => {
                if (!this.searchQuery.trim()) {
                    this.getFiles();
                    return;
                }
                this.getFiles(this.searchQuery.trim());
            }, 300);
        },

        startOperationMonitor() {
            this.operationMonitor = setInterval(() => {
                this.checkOperationStatus();
            }, 5000);
        },

        stopOperationMonitor() {
            if (this.operationMonitor) {
                clearInterval(this.operationMonitor);
                this.operationMonitor = null;
            }
        },

        checkOperationStatus() {
            const isAnyOperationActive = !this.standBy;
            if (!isAnyOperationActive && this.isLoading) {
                this.isLoading = false;
            }
        }
    }
};