<!-- LoadingSpinner.vue -->
<template>
    <transition name="fade">
        <div class="loader-container">
            <div class="loader-content">
                <div class="loader-circle"></div>
                <div class="loader-text">{{ text }}</div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    props: {
        text: {
            type: String,
            default: 'Carregando...'
        }
    }
}
</script>

<style scoped>

* {
    font-family: 'Arial', 'Helvetica', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(255, 255, 255, 0.9); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(2px);
}

.loader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-radius: 8px;
    /* background: white; */
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.loader-circle {
    width: 40px;
    height: 40px;
    border: 3px solid #f0f0f0;
    border-top: 3px solid #8f2808;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

.loader-text {
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Animação de fade */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>